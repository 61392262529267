// noinspection JSUnusedGlobalSymbols
import React from 'react'
import '../styles/styles.scss'
import Index from '../components/index'
import { SEO } from '../components/seo'

const IndexEn = (props) => <Index location={props.location}/>

export default IndexEn

export const Head = () => (
  <SEO />
)
